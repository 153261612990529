import { Text } from "theme-ui";

import { Row } from "../../ui/box";
import { Button } from "../../ui/button";
import { Card } from "../../ui/card";
import { Link } from "../../ui/link";

interface DbtModelsProps {
  enabled: boolean;
  id?: string;
}

export const DbtModels = ({ id, enabled }: DbtModelsProps): JSX.Element => {
  return (
    <Card size="small" sx={{ maxWidth: "500px" }}>
      <Row sx={{ alignItems: "center", justifyContent: "space-between" }}>
        <Text sx={{ mr: 4 }}>dbt Models is {enabled ? "enabled" : "disabled"}</Text>
        <Row sx={{ alignItems: "center" }}>
          <Link sx={{ mr: 2 }} to={`${import.meta.env.VITE_DOCS_URL}/models/dbt-models`}>
            <Button propagate variant="secondary">
              Learn more
            </Button>
          </Link>

          <Link to={`/extensions/dbt-models/configuration/${id}`}>
            <Button propagate variant="dark">
              {enabled ? "Configure" : "Enable"}
            </Button>
          </Link>
        </Row>
      </Row>
    </Card>
  );
};
