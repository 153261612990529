import { useEffect, useState } from "react";

import { useResourceLabelsQuery } from "../../graphql";

type Label = { key: string; values: string[] };
interface UseLabels {
  labelsLoading: boolean;
  labels: Label[];
}
export const useLabels = (): UseLabels => {
  const { data, isLoading } = useResourceLabelsQuery();
  const [reducedLabels, setReducedLabels] = useState<Label[]>([]);

  useEffect(() => {
    /**
     * Right now resource tags are 1:1. This mapping may change at a future point
     * Remove duplicate keys to have a { tag: [values] } mapping
     */
    if (data?.resource_tag_values) {
      const labels = data.resource_tag_values.reduce((acc: Label[], curr) => {
        if (!curr.key) {
          return acc;
        }
        const label = acc.find((l) => l.key === curr.key);
        if (label) {
          label.values = Array.from(new Set<string>([...label.values, ...curr.values]));
        } else {
          acc.push({ key: curr.key, values: curr.values });
        }
        return acc;
      }, []);
      setReducedLabels(labels);
    }
  }, [data?.resource_tag_values]);
  return { labels: reducedLabels, labelsLoading: isLoading };
};
